<template>
  <div class="WJgroupIntroduce">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="@/assets/image/induceBanner.jpg" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{news.typename}}</p>
              <!-- <p class="WJgroup-subtitle">WEIGA Group Introduction</p> -->
            </div>
            <div class="WJgroupIntroduce-title padding-tb70">
              <h1>{{$t('introduce.slogan')}}</h1>
              <h2 v-if="lang=='zh'" ><span class="hidden-xs">—— </span>{{$t('introduce.subSlogan1')}}<br class="hidden-sm-and-up"> {{$t('introduce.subSlogan2')}}<span  class="hidden-xs">——</span></h2>
              <h2 v-else ><span class="hidden-xs">—— </span>{{$t('introduce.subSlogan')}} <span  class="hidden-xs">——</span></h2>
              <div class="WJgroupIntroduce-titleIMg flex justify_center">
                <img src="../../../assets/image/bglight.png" alt="">
              </div>
            </div>
            <ul class="WJgroupIntroduce-num flex justify_center align-start">
              <li class="">
                <count-to separator="" :start-val="0" :end-val="2002" :duration="1" class="h2" />
<!--                <h2 class="h2">2002</h2>-->
                <!-- <count-to :start-val="0" :end-val="2004" :duration="3200" class="h2" /> -->
<!--                成立于-->
                <p>{{$t('introduce.establish',{num:'2002'})}}</p>
              </li>
              <li class="borderI-left">
                <!-- <h2>1000+</h2> -->
                <count-to :start-val="0" :end-val="1000" :duration="3200" class="h2" />
<!--                拥有员工-->
                <p>{{$t('introduce.staff',{num:'1000'})}}</p>
              </li>
              <li class="borderI-left">
                <!-- <h2>2700</h2> -->
                <count-to :start-val="0" :end-val="2700" :duration="3200" class="h2" />
<!--                达成客户-->
                <p>{{$t('introduce.custom',{num:'2700'})}}</p>
              </li>
              <li class="borderI-left">
                <!-- <h2>300</h2> -->
                <count-to :start-val="0" :end-val="300" :duration="3200" class="h2" />
<!--                开发总量突破-->
                <p>{{$t('introduce.develop',{num:'300'})}}</p>
              </li>
              <li class="borderI-left">
                <!-- <h2>40</h2> -->
                <count-to :start-val="0" :end-val="40" :duration="3200" class="h2" />
                <p>{{$t('introduce.operate',{num:'40'})}}</p>
              </li>
            </ul>
          </div>
          <div class="WJ-font">
            <p v-html="news.content"></p>

          </div>
          <div class="WJgroupIntroduce-idea margin-tb70  ">
            <div class="WJgroupIntroduce-ideaIn flex align-start">
              <div class="WJgroupIntroduce-ideaL flex flex_column justify_center">
<!--                企业文化-->
                <h1>{{$t('introduce.cultureTitle')}}</h1>
                <h6 class="hidden-xs-only"><span class="i-font">{{$t('introduce.cultureSubtitle1')}}</span><span class="i-dian hidden-xs"> · </span><span class="i-font">{{$t('introduce.cultureSubtitle2')}}</span><span class="i-dian hidden-xs"> · </span><span class="i-font">{{$t('introduce.cultureSubtitle3')}}</span></h6>
              </div>
              <div class="WJgroupIntroduce-ideaR flex1">
<!--               发展使命 企业理念 核心价值观-->
                <div class="WJgroupIntroduce-ideaRt flex justify_between ">
                  <p :class="onActive==1?'wjpactive':''" @click="choseOne(1)">{{$t('introduce.mission')}}</p>
                  <p :class="onActive==2?'wjpactive':''" @click="choseOne(2)">{{$t('introduce.idea')}}</p>
                  <p :class="onActive==3?'wjpactive':''" @click="choseOne(3)">{{$t('introduce.values')}}</p>
                </div>
                <div class="WJgroupIntroduce-ideaRC " v-show="onActive==2">
<!--                  以人为本-->
                  <h5>{{$t('introduce.missionSub1')}}</h5>
<!--                  一枝独秀，终不敌满园春光。以“人”为核心，育才以怀才抱器，信-->
                  <h6>{{$t('introduce.missionDesc1')}}</h6>
<!--                  志同道合-->
                  <h5>{{$t('introduce.missionSub2')}}</h5>
                  <h6>{{$t('introduce.missionDesc2')}}</h6>
<!--                  诚信感恩-->
                  <h5>{{$t('introduce.missionSub3')}}</h5>
                  <h6>{{$t('introduce.missionDesc3')}}</h6>
<!--                  以人为本-->
                  <h5>{{$t('introduce.missionSub4')}}</h5>
                  <h6>{{$t('introduce.missionDesc4')}}</h6>
                </div>
                <div v-show="onActive==1" class="WJgroupIntroduce-ideaRC WJgroupIntroduce-ideaRC-M flex flex_column justify_center " style="" >
<!--                  维和谐之善举，铸臻善之佳作-->
                  <h5 style="margin: 0;">{{$t('introduce.ideaSub1')}}</h5>
                  <h5 >{{$t('introduce.ideaSub2')}}</h5>
                  </div>
                  <div v-show="onActive==3" class="WJgroupIntroduce-ideaRC WJgroupIntroduce-ideaRC-M flex flex_column justify_center " style="" >
<!--                    服务社会，发展自己是我们的努力方向-->

                    <h5 style="margin: 0;" class="hidden-xs">{{$t('introduce.valuesSub1')}}</h5>
                    <h5 class="hidden-xs">{{$t('introduce.valuesSub2')}}</h5>
                    <template v-if="lang=='zh'">
                      <div>
                      <h5  style="margin: 0;" class="hidden-sm hidden-md hidden-md hidden-lg" >服务社会,</h5>
                      <h5  class="hidden-sm hidden-md hidden-md hidden-lg">发展自己是我们的努力方向</h5>
                      </div>
                      <div>
                      <h5  class="hidden-sm hidden-md hidden-md hidden-lg">自己发展,</h5>
                      <h5 class="hidden-sm hidden-md hidden-md hidden-lg">回馈社会是我们的永恒主题</h5>
                      </div>
                    </template>
                    <template v-else>
                      <h5 style="margin: 0;" class="hidden-sm hidden-md hidden-md hidden-lg">{{$t('introduce.valuesSub1')}}</h5>
                      <h5 class="hidden-sm hidden-md hidden-md hidden-lg">{{$t('introduce.valuesSub2')}}</h5>
                    </template>
                    </div>
              </div>
            </div>
            <!-- <ul>
              <li class="flex wow animate__animated fadeInUpBig " data-wow-delay="0.1s">
                <h4>发展理念</h4>
                <h5>维善筑佳 · 明德致远</h5>
              </li>
              <li class="flex wow animate__animated fadeInUpBig " data-wow-delay="0.2s">
                <h4>企业理念</h4>
                <h5>以人为本、志同道合、诚信感恩、追求完美</h5>
              </li>
              <li class="flex wow animate__animated fadeInUpBig " data-wow-delay="0.3s">
                <h4>企业价值观</h4>
                <h5>服务社会，发展自我；自我发展，回馈社会</h5>
              </li>
            </ul> -->
          </div>

        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  import CountTo from 'vue-count-to'
  import {handleTree} from "@/utils";
  export default {
    name: "WJgroupIntroduce",
    components: {
      CountTo
    },
    data() {
      return {
        menuActiveIndex: '/WJgroupIntroduce',
        menu: {
          name: '维佳集团',
          path: '/WJgroupIntroduce',
          list: [{
            name: this.$t('menu.Introduction'),
            path: '/WJgroupIntroduce',
          }, {
            name: this.$t('menu.Message'),
            path: '/WJgroupSendWord',
          },{
            name: this.$t('menu.develop'),
            path: '/WJgroupDevelop',
          }, {
            name:this.$t('menu.honour'),
            path: '/WJgroupHonor',
          }, {
            name:this.$t('menu.team'),
            path: '/WJgroupTeam',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.group'),
          path: '/WJgroupIntroduce',
        }, {
          name: this.$t('menu.Introduction'),
          path: '/WJgroupIntroduce',
        }],
        onActive:1,
        news:{},
        typeid:72
      };
    },
    computed: {
      menuList(){
        let menuList=[];
        if(this.$store.state.article.channelList && this.$store.state.article.channelList.length>0){
          let type = this.$store.state.article.channelList.find(item=>{
            return item.id==72
          })
          menuList = this.$store.state.article.channelList.filter(item=>{
            return item.parent_id == type.parent_id;
          })
          console.log(menuList)
        }
        return menuList;
      },
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {
      this.getData();
    },
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiType_1:'ekey=1&typeid='+this.typeid+'&addfields=content,content_en',//获取栏目详情
        }).then(res=>{
          this.news =this.$utils.translate(res.apiType[1].data);
        })
      },
      choseOne(e){
        this.onActive=e
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .gIntroduce-banner {
    width: 100%;
    height: auto;
  }

  .gIntroduce-banner img {
    width: 100%;
    height: auto;
  }



  .WJgroupIntroduce-title {
    text-align: center;
    position: relative;
    padding-bottom: 140px !important;
  }

  .WJgroupIntroduce-titleIMg {
    width: 100%;
    height: 148px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .WJgroupIntroduce-titleIMg img {
    height: 100%;
  }

  .WJgroupIntroduce-title h1 {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupIntroduce-title h2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupIntroduce-num {
    padding: 40px;
    background: linear-gradient(266deg, #967353 0%, #D5B88E 100%);
    border-radius: 4px;
    position: relative;
    text-align: center;
  }

  .WJgroupIntroduce-num li {
    width: 18%;
    flex-shrink: 0;
    padding: 0 10px;
    height: 100%;
  }

  .WJgroupIntroduce-num li .h2 {
    font-size: 52px;
    line-height: 54px;
    font-family: Impact;
    font-weight: 400;
    color: #FFFFFF;
  }

  .WJgroupIntroduce-num li p {
    margin-top: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }

  .WJgroupIntroduce-idea {
    width: 100%;
    min-height: 350px;
    background: url(../../../assets/image/bgbottom.png) no-repeat;
    background-size: cover;
    padding: 38px;
    box-sizing:border-box;
  }

  .WJgroupIntroduce-ideaIn {

    height: 492px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.29);
    padding: 40px 30px;
  }

  .WJgroupIntroduce-ideaL {
    width: 27%;
    border-right: 2px solid rgba(255, 255, 255, 0.29);
    height: 100%;
    flex-shrink: 0;
  }

  .WJgroupIntroduce-ideaL h1 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #FFFFFF;
  }

  .WJgroupIntroduce-ideaL h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 17px;

  }
.WJgroupIntroduce-ideaRt{
  margin-bottom: 45px;
}
 .WJgroupIntroduce-ideaRt p{
    font-style: normal;
    // font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
 }
 .WJgroupIntroduce-ideaRt p.wjpactive{
   position: relative;
 }
 .WJgroupIntroduce-ideaRt .wjpactive::after{
    content: "";
   position: absolute;
   bottom:-4px;
   left: -1px;
  width: 100%;
  height: 2px;
  background-color: #fff;

 }
 .WJgroupIntroduce-ideaRC {
   min-height: 366px;
 }
 .WJgroupIntroduce-ideaRC h5{


    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 30px;
 }
 .WJgroupIntroduce-ideaRC h6{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 15px;
    text-align: left;

 }
 .WJgroupIntroduce-ideaR{
    margin: 0 0% 0 3%;
 }
 .borderI-left{
   position: relative;
    &:before{
      content:'';
      position: absolute;
      top:10px;
      left: 0;
      height: 94px;
      width: 1px;
      background-color: #fff;
      //margin-top: -40px;
    }
 }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupIntroduce-title h2{
      font-size: 34px;
    }
    .WJgroupIntroduce-num li p{
      font-size: 34px;
      line-height: 44px;
    }
    .WJgroupIntroduce  .WJgroupIntroduce-num{
      flex-wrap: wrap;
    }
   .WJgroupIntroduce .WJgroupIntroduce-num li:nth-child(1){
      width: 26%;
      flex-shrink: 0;
    }
    .WJgroupIntroduce .WJgroupIntroduce-num li:nth-child(2){
      width: 34%;
      flex-shrink: 0;
    }
    .WJgroupIntroduce .WJgroupIntroduce-num li:nth-child(3){
      width: 33%;
      flex-shrink: 0;
    }
    .WJgroupIntroduce .WJgroupIntroduce-num li:nth-child(4){
      width: 44%;
      flex-shrink: 0;
      margin-top: 40px;
      &:before{
        content:'';
        display: none;
        //margin-top: -40px;
      }
    }
    .WJgroupIntroduce .WJgroupIntroduce-num li:nth-child(5){
      width: 48%;
      flex-shrink: 0;
      margin-top: 40px;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaL h1{
      font-size: 39px;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaL h6{
      font-size: 34px;
      line-height: 44px;
     .i-font{
       display: block;
     }
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaRt p{
      font-size: 38px;
      line-height: 44px;
    }
    .WJgroupIntroduce  .WJgroupIntroduce-ideaRC{
      //align-items: start;
    }
    .WJgroupIntroduce  .WJgroupIntroduce-ideaRC h5{
      font-size: 37px;
      line-height: 36px;
      word-break: break-all;
      line-break:anywhere;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaRC h6{
      font-size: 37px;
      line-height: 47px;font-weight: normal;
    }
    .WJgroupIntroduce .WJgroupIntroduce-idea{
      min-height: 1250px;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaIn{
      height:1100px;
      flex-direction: column;
      background:url("../../../assets/image/bgbottom-M.png") no-repeat;
      background-position:100% 100%;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaL{
      height: 100px;
      border:0;
      width: 100%;
      margin-bottom: 30px;
      h1{
        font-size: 54px;
      }
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaR{
      width: 100%;
      margin-left: 0;
    }
    .WJgroupIntroduce .WJgroupIntroduce-ideaRC{
      min-height: 820px;
    }
    .WJgroupIntroduce-ideaRC-M{
      //align-items: center!important;
    }
  }
</style>
<style>
@media screen and (min-width:320px) and (max-width:767px) {
  .WJgroupIntroduce .WJ-font p{
    font-size: 34px!important;
    line-height: 44px;
  }
  .WJgroupIntroduce .WJ-font p span{
    font-size: 34px!important;
    line-height: 44px;
  }
}
</style>
